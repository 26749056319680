<template>
  <JetDialogData
    title="Добавить транспортные средства"
    v-model="dialog"
    width="900"
    :current-tab="tab"
  >
    <!-- Основная страница -->
    <template #tab-1>
      <div class="pa-2">
        <!-- Строка с выбором дат -->
        <v-row>
          <!-- Дата начала настройки -->
          <v-col class="pr-2">
            <v-menu
              offset-y
              transition="scale-transition"
              max-width="290px"
              max-height="290px"
              v-model="startDate.showPicker"
              :close-on-content-click="false"
            >
              <template #activator="{ on }">
                <v-text-field
                  readonly
                  persistent-hint hide-details
                  label="Дата начала настройки"
                  prepend-icon="mdi-calendar"
                  v-on="on"
                  :value="formatStartDate"
                ></v-text-field>
              </template>

              <v-date-picker
                no-title
                v-model="startDate.value"
                @input="startDate.showPicker = false"
                :first-day-of-week="1"
                :weekday-format="dayOfWeekToFormat"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <!-- Дата окончания настройки -->
          <v-col class="pl-2">
            <v-menu
              offset-y
              transition="scale-transition"
              max-width="290px"
              max-height="290px"
              v-model="endDate.showPicker"
              :close-on-content-click="false"
            >
              <template #activator="{ on }">
                <v-text-field
                  readonly
                  persistent-hint hide-details
                  label="Дата окончания настройки"
                  prepend-icon="mdi-calendar"
                  v-on="on"
                  :value="formatEndDate"
                  clearable
                ></v-text-field>
              </template>

              <v-date-picker
                no-title
                v-model="endDate.value"
                :min="startDate.value"
                @input="endDate.showPicker = false"
                :first-day-of-week="1"
                :weekday-format="dayOfWeekToFormat"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <!-- Строка с выбором организации и перевозчика -->
        <v-row>
          <v-col class="py-0">
            <v-autocomplete
              label="Организация"
              item-value="id"
              item-text="title"
              return-object
              v-model="organization.selected"
              :items="organization.data"
              :loading="organization.loading"
              :filter="filterOrganizations"
            >
              <template #item="{item: org}">
                <div id="selectForAddVehicle">
                  <div>
                    {{ org.id === 'headerForOrganizations' ? org.creationdate : formatDate(org.creationdate) }}
                  </div>
                  <div>
                    {{ org.id === 'headerForOrganizations' ? org.enddt : formatDate(org.enddt) }}
                  </div>
                  <div>
                    {{ org.name }}
                  </div>
                  <div>
                    {{ org.title }}
                  </div>
                  <div style="justify-self: center; align-self: center">
                    <div v-if="org.id === 'headerForOrganizations'">
                      {{ org.blocked }}
                    </div>
                    <v-checkbox
                      v-else
                      v-model="org.blocked"
                      class="mt-0 pt-0"
                      disabled hide-details
                    ></v-checkbox>
                  </div>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <v-autocomplete
              :disabled="!organization.selected.id"
              label="Перевозчик"
              item-value="id"
              item-text="title"
              return-object
              v-model="carrier.selected"
              :items="carrier.data"
              :loading="carrier.loading"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-btn
          color="primary"
          @click="onAddVehicle"
          :disabled="!carrier.selected.id"
        >
          Добавить ТС
        </v-btn>

        <!-- Строка с таблицей -->
        <v-row>
          <v-col>
            <v-data-table
              :headers="table.headers"
              :items="table.data"
              :no-data-text="table.noDataText"
              height="400"
            >
              <template #item.actions="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      fab x-small depressed
                      v-on="on"
                      v-bind="attrs"
                      @click="showRemoveDialog(item)"
                    >
                      <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                  </template>

                  Удалить
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <v-btn color="primary" @click="onApplyClick" :disabled="!table.data.length" :loading="loadingApplyButton">
              Применить
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </template>

    <!-- Таблица ТС -->
    <template #tab-2>
      <div class="pa-2">
        <v-row justify="center" align="center">
          <v-col cols="auto" class="pt-0">
            Для выделенных транспортных средств уже существует связь с организацией
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              label="Поиск ТС"
              v-model="vehicles.filter"
              hide-details
              clearable
              class="pt-0 mt-0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          v-model="vehicles.selected"
          style="min-height: 400px"
          show-select
          :loading="vehicles.loading"
          :headers="vehicles.headers"
          :items="vehicles.data"
          :no-data-text="vehicles.noDataText"
          :item-class="item => !item.isSelectable ? 'c_selected' : ''"
          :search="vehicles.filter"
        ></v-data-table>
        <v-row>
          <v-col align="right">
            <!-- Кнопки таблицы ТС -->
            <v-btn
              color="primary"
              @click="closeTab"
            >
              Отмена
            </v-btn>
            <v-btn
              class="ml-4"
              color="primary"
              @click="addRows"
              :disabled="vehicles.selected.length === 0"
            >
              Выбрать
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </template>

    <!-- Диалог удаления записи -->
    <JetDialog
      title="Подтвердите действие"
      v-model="deleteDialog.show"
      @on-cancel-click="onRemoveCancelClick"
      @on-apply-click="onRemoveApplyClick"
    >
      Вы уверены что хотите удалить ТС?
    </JetDialog>
  </JetDialogData>
</template>

<script>
// Утилиты
import { prepareSinRequest, prepareSinResponse } from '@/utils/http';
import { findObjectIndex, uuidv4 } from '@/utils/utils';
import md5 from 'blueimp-md5';
import { dayOfWeekToFormat, formatDate, FORMAT_FOR_USER } from '@/services/JetDate';

// Миксины
import BaseAction from '@/components/actions/BaseAction';

// Компоненты
import JetDialogData from '@/components/JetDialogData';
import JetDialog from '@/components/JetDialog';

export default {
  name: 'AddVehicles',
  components: {
    JetDialogData,
    JetDialog,
  },
  mixins: [
    BaseAction,
  ],
  data: () => ({
    // Вкладка
    tab: 0,
    // Флаг для отображения диалога
    dialog: true,
    // Начало даты
    startDate: {
      // Показ выбора даты
      showPicker: false,
      // Выбранная дата
      value: null,
    },
    // Окончания даты
    endDate: {
      // Показ выбора даты
      showPicker: false,
      // Выбранная дата
      value: null,
    },
    // Настройка таблицы
    table: {
      // Заголовки
      headers: [
        { text: 'Дата начала', value: 'dateBegin' },
        { text: 'Дата окончания', value: 'dateEnd' },
        { text: 'Организация', value: 'orgName' },
        { text: 'Перевозчик', value: 'carrierName' },
        { text: 'ТС', value: 'govnum' },
        { text: '', value: 'actions', sortable: false, align: 'center' },
      ],
      // Данные
      data: [],
      // Текст когда нет данных
      noDataText: 'Необходимо выбрать организацию и перевозчика',
    },
    // Организация
    organization: {
      // Список
      data: [],
      // Признак что происходит загрузка
      loading: false,
      // Выбраная запись
      selected: {
        title: '',
        id: '',
      },
    },
    // Перевозчик
    carrier: {
      // Список
      data: [],
      // Признак что происходит загрузка
      loading: false,
      // Выбраная запись
      selected: {
        title: '',
        id: '',
      },
    },
    // ТС
    vehicles: {
      // Список
      data: [],
      // Признак что происходит загрузка
      loading: false,
      // Выбраная запись
      selected: [],
      // Заголовки
      headers: [
        { text: 'Гос.номер', value: 'govnum' },
      ],
      // Текст когда нет данных
      noDataText: 'ТС отсутствуют',
      // Фильтрация
      filter: null,
    },
    // Диалог удаления
    deleteDialog: {
      // Флаг для отображения
      show: false,
      // Удаляемый элемент
      item: null,
    },
    // Данные, которые будут удалены после подтверждения
    deleteRows: [],
    // Кнопка выбрать
    loadingApplyButton: false,
    dayOfWeekToFormat: () => '',
  }),
  created() {
    this.dayOfWeekToFormat = dayOfWeekToFormat;
    this.startDate.value = new Date().toISOString().substr(0, 10);
    this.loadOrganization();
    this.loadCarrier();
  },
  watch: {
    selectedCarrier(value) {
      if (value) {
        this.loadCarrierVehicles();
      }
    },
  },
  methods: {
    // Методы для автокомплита Организации
    formatDate(date) {
      return formatDate(date, FORMAT_FOR_USER.ONLY_DATE, true);
    },
    filterOrganizations(item, queryText, itemText) {
      if (item?.id === 'headerForOrganizations') return true;
      for (const propValues of Object.values(item)) {
        if (propValues && typeof propValues === 'string') {
          if (propValues.toLowerCase().indexOf(queryText.toLowerCase()) > -1) {
            return true;
          }
        }
      }
      return false;
    },

    // @Override
    baseActionCheck_() {
      return false;
    },

    // Форматирование дат
    _formatDate(date) {
      if (date) {
        const [year, month, day] = date.split('-');
        return `${day}.${month}.${year}`;
      }
      return null;
    },

    // Загрузка организаций
    async loadOrganization() {
      this.organization.loading = true;

      const res = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          'sin2:/v:bec3f50f-77f6-47a4-a7c8-95554cbb2579/?hierarchy=false',
        ),
        {
          hideUnderscores: true,
        },
      );

      this.organization.loading = false;
      this.organization.data = [
        {
          creationdate: 'Дата создания',
          enddt: 'Дата окончания',
          name: 'Код',
          title: 'Наименование',
          blocked: 'Блокировка',
          disabled: true,
          id: 'headerForOrganizations',
        },
        ..._copy(res),
      ];
    },

    // Загрузка перевозчиков
    async loadCarrier() {
      this.carrier.loading = true;

      const res = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          'sin2:/v:15e0fc9d-421c-4bc8-b1a3-0788bec32400/',
        ),
        {
          hideUnderscores: true,
        },
      );
      for (const carr of res) {
        carr.title = carr.orgidOrgcode + ' ' + carr.orgidShortname;
      }

      this.carrier.loading = false;
      this.carrier.data = _copy(res);
    },

    // Проверка пары перевозчика и организации
    async checkPair() {
      const orgId = this.organization.selected.id;
      const carrierId = this.carrier.selected.id;
      const query = `sin2:/v:faba5be0-feae-450f-b7ef-83fd830ac20b/?filter=and(eq(field(".carrierID"),param("${carrierId}", "id")),eq(field(".tenant"),param("${orgId}", "id")))`;
      await this.$store.dispatch('cache/removeItem', md5('core-read' + query));
      const res = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          query,
        ),
        {
          hideUnderscores: true,
        },
      );
      return res;
    },

    // Загрузка транспортных средств перевозчика
    async loadCarrierVehicles() {
      this.vehicles.loading = true;
      const pair = await this.checkPair();

      const res = await prepareSinResponse(
        await prepareSinRequest(
          'core-read',
          `sin2:/v:f523ec5e-f8ea-4b03-8af2-95e37a9762ed/?filter=eq(field(".crrid"),param("${this.selectedCarrier.id}", "id"))`,
          '',
          ['id', 'govnum'],
        ),
        {
          hideUnderscores: true,
        },
      );
      res.forEach((r) => {
        r.isSelectable = true;
      });
      for (const p of pair) {
        if (p.vehicle) {
          for (const r of res) {
            if (r.isSelectable && p.vehicle === r.id) {
              r.isSelectable = false;
            }
          }
        } else {
          this.deleteRows.push(p);
        }
      }
      this.vehicles.loading = false;
      this.vehicles.data = _copy(res);
    },

    // Показ диалога на удаление записи
    showRemoveDialog(item) {
      this.deleteDialog.show = true;
      this.deleteDialog.item = _copy(item);
    },
    // Отмена удаления записи
    onRemoveCancelClick() {
      this.deleteDialog.show = false;
      this.deleteDialog.item = null;
    },
    // Удаление записи
    onRemoveApplyClick() {
      if (this.deleteDialog.item !== null) {
        const index = findObjectIndex(this.deleteDialog.item, this.table.data);

        if (index > -1) {
          this.table.data.splice(index, 1);
        }
      }
      this.deleteDialog.show = false;
      this.deleteDialog.item = null;
    },

    onAddVehicle() {
      const startDate = this.startDate.value;
      const endDate = this.endDate.value;
      if (endDate !== null && ((new Date(endDate) - new Date(startDate)) < 0)) {
        this.showMessage('warning', 'Дата окончания не может быть меньше даты начала');
      } else {
        this.tab = 1;
      }
    },

    closeTab() {
      this.tab = 0;
      this.vehicles.selected = [];
    },

    // Добавить запись в таблицу
    async addRows() {
      const tmp = [];
      const oN = this.organization.selected.title;
      const cN = this.carrier.selected.title;
      for (const vehicle of this.vehicles.selected) {
        if (vehicle.id === '') {
        }
        const row = {
          dateBegin: this.startDate.value,
          dateEnd: this.endDate.value,
          orgName: oN.substring(0, 22) + (oN.length > 22 ? '...' : ''),
          orgId: this.organization.selected.id,
          carrierName: cN.substring(0, 22) + (cN.length > 22 ? '...' : ''),
          carrierId: this.carrier.selected.id,
          vehicleId: vehicle.id,
          govnum: vehicle.govnum,
        };
        if (findObjectIndex(row, tmp) === -1) {
          tmp.push(row);
        }
      }

      let save = true;
      if (this.deleteRows.length > 0) {
        save = await jet.confirm({
          title: 'Предупреждение',
          msg: `Для организации [${oN}] уже заведен перевозчик [${cN}] без ТС. Записи без ТС для данного перевозчика будут удалены при сохранении. Продолжить?`,
        });
      }

      if (save) {
        this.table.data = _copy(tmp);
      } else {
        this.deleteRows = [];
      }
      this.tab = 0;
      this.vehicles.selected = [];
    },

    async onApplyClick() {
      this.loadingApplyButton = true;
      const deletePromises = [];
      for (const row of this.deleteRows) {
        deletePromises.push(
          jet.http.post({
            type: 'core-delete',
            query: 'sin2:/v:faba5be0-feae-450f-b7ef-83fd830ac20b/',
            params: [{ id: 'id', type: 'id', value: row.id }],
          }),
        );
      }
      await Promise.all(deletePromises);
      this.deleteRows = [];
      const createPromises = [];
      for (const row of this.table.data) {
        createPromises.push(
          jet.http.post({
            type: 'core-create',
            query: 'sin2:/v:faba5be0-feae-450f-b7ef-83fd830ac20b/',
            params: [
              { id: 'id', type: 'id', value: uuidv4() },
              { id: 'carrierID', type: 'id', value: row.carrierId },
              { id: 'tenant', type: 'id', value: row.orgId },
              { id: 'vehicle', type: 'id', value: row.vehicleId },
              { id: 'crud', type: 'string', value: 'r' },
              { id: 'startDt', type: 'date', value: new Date(this.startDate.value).getTime() },
              {
                id: 'endDt',
                type: 'date',
                value: this.endDate.value === null ? null : new Date(this.endDate.value).getTime(),
              },
            ],
          }),
        );
      }
      await Promise.all(createPromises);
      this.loadingApplyButton = false;
      this.dialog = false;
      this.collection.refresh();
    },
  },
  computed: {
    // Форматированная дата начала
    formatStartDate() {
      return this._formatDate(this.startDate.value);
    },
    // Форматированная дата окончания
    formatEndDate() {
      return this._formatDate(this.endDate.value);
    },
    selectedOrganization() {
      return this.organization.selected;
    },
    selectedCarrier() {
      return this.carrier.selected;
    },
  },
};
</script>

<style scoped>
>>> .c_selected {
  background: lightgray;
}
</style>

<style lang="sass">
#selectForAddVehicle
  display: grid
  grid-template-columns: 1fr 1fr 1.2fr 1.5fr 0.5fr
  width: 100%
  max-width: 800px

#selectForAddVehicle > div
  padding: 7px
  max-height: 50px
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
</style>
